.descriptionPopover {
    display: inline-block;
    color: $colorPrimary;

    &.open {
        visibility: visible !important;
    }

    &__body {
        .MuiPaper-root {
            width: rem(400);
            padding: rem(15);
            border-radius: rem(8);
        }
    }

    &__title {
        display: block;
        color: $colorText;
        font-size: rem(16);
        font-weight: bold;
        margin-bottom: rem(5);
    }

    &__description {
        max-width: 100%;
        font-size: rem(14);
        max-height: rem(150);
        overflow: hidden;
        font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif !important;

        &--emptyState {
            color: $colorText--information;
        }

        strong {
            font-weight: 600 !important;
        }

        a {
            word-break: break-word;
            @include linkify();
        }

        h2, h3 {
            margin: rem(4) 0 !important;
        }

        p {
            margin: 0 !important;
        }

        ol, ul {
            margin: 0 !important;
            padding-inline-start: rem(30) !important;
        }
    }

    &__buttonContainer {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        height: rem(40);
        padding: 0 rem(15);
        align-items: stretch;
        box-shadow: 0 rem(-5) rem(10) 0 white;
    }

    &__seeMore {
        display: flex;
        font-weight: 500;
        font-size: rem(14);
        min-width: initial;
        height: initial;
        padding: 0;
        align-self: stretch;
        align-items: center;
        background: none;
        cursor: pointer;

        @include linkify();
    }
}
