.errorPage {
    display: flex;
    position: relative;
    top: rem(40);
    min-width: rem(1000);
    background-color: white;
    border-radius: rem(15);
    box-shadow: 0 rem(5) rem(15) rgba(0, 0, 0, 0.08);
    overflow: hidden;
    flex: 1 1 auto;

    &__contentBrowser {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        flex-direction: column;
    }
}
