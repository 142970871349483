.resetPassword {
    &__link {
        color: $colorText--information;
        font-size: rem(16);
        margin-top: rem(15);
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
