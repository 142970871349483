.recommendationCard {
    padding: rem(16);
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: white;
    border-radius: rem(20);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    font-style: normal;
    line-height: 150%;
    align-items: center;

    &__title {
        margin-bottom: rem(4);
        font-size: rem(18);
        font-weight: 600;
    }

    &__description {
        font-size: rem(14);
        font-weight: normal;
    }

    &__primaryAction {
        grid-row: span 2;
        display: flex;
        align-items: center;
        color: $springGreen--activated;
        :hover {
            cursor: pointer;
        }
    }
}
