.radioButton {
    @mixin calculateSize($size) {
        width: rem($size);
        height: rem($size);
        border-radius: rem($size / 2);
    }

    @mixin containerSize($size) {
        width: rem($size + 20);
        height: rem(44);
    }

    // Level selector button sizes
    $xs: 12;
    $sm: 16;
    $md: 20;
    $lg: 24;
    $xl: 28;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(&--disabled) {
            cursor: pointer;
        }

        &--xs {
            @include containerSize($xs);
        }

        &--sm {
            @include containerSize($sm);
        }

        &--md {
            @include containerSize($md);
        }

        &--lg {
            @include containerSize($lg);
        }

        &--xl {
            @include containerSize($xl);
        }

    }

    background-color: $colorInput;
    transition: background-color 0.35s ease;
    min-width: initial;
    padding: 0;
    border: none;

    &:not(&--disabled) {
        &:focus,
        &:active {
            box-shadow: 0 0 0 rem(2) $colorPrimary--opaque;
        }
    }

    &--active {
        background-color: $colorPrimary;
    }

    &--loading {
        background-color: $colorPrimary--opaque;
    }

    &--disabled {
        cursor: default;
    }

    &--xs {
        @include calculateSize($xs);
    }

    &--sm {
        @include calculateSize($sm);
    }

    &--md {
        @include calculateSize($md);
    }

    &--lg {
        @include calculateSize($lg);
    }

    &--xl {
        @include calculateSize($xl);
    }
}
