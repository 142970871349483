.confirmDialog {
    &__body {
        display: flex;

        .MuiPaper-root {
            width: rem(375);
            padding: rem(20);
            border-radius: rem(15);
            box-shadow: 0 rem(4) rem(15) rgba(35, 31, 32, 0.15);
            margin-top: rem(-6);
            z-index: 1500;
            position: absolute !important;
            overflow: hidden;
        }
    }

    &__title {
        line-height: rem(25);
        font-size: rem(20);
        font-weight: 600;
    }

    &__content {
        width: 100%;
        height: rem(92);
        padding-top: rem(5);
        font-size: rem(18);
        line-height: rem(23);
        font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif !important;

    }

    &__footer {
        padding-bottom: rem(20);
        font-size: rem(18);
        line-height: rem(23);
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        button {
            margin: 0;

            &:not(:last-child) {
                margin-right: rem(10);
            }
        }
    }
}
