.searchResults {
    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-width: 1000px;
        padding: 16px 0;
    }
    &__worksheet {
        flex: 0 0 auto;

        &--expand {
            flex: 1 1 auto;
        }
    }
}
