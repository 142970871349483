.margin-top-xs {
  margin-top: 4px
}
.margin-top-sm {
  margin-top: 8px
}
.margin-top-md {
  margin-top: 16px
}
.margin-top-lg {
  margin-top: 24px
}
.margin-top-xl {
  margin-top: 32px
 }