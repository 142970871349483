.fade {
    &-out {
        animation: 0.5s linear fade reverse;
    }

    &-in {
        animation: 0.5s linear fade;
    }
}

.shake {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: rem(1000);
}

.slideFadeBottomToTop {
    animation: 0.35s ease-out slideFadeBottomToTop;
}

.animation-slide {
    &-down {
        &-out {
            animation: 0.3s linear slideFadeDown reverse;

            &.animation-slow {
                animation-duration: 0.4s;
            }
        }

        &-in {
            animation: 0.3s linear slideFadeDown;

            &.animation-slow {
                animation-duration: 0.4s;
            }
        }
    }
}

.animated-checkmark {
    animation-duration: 1000ms;
    animation-timing-function: steps(60);
    animation-fill-mode: forwards;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
}

.animated-checkmark.play {
    animation-name: play60;
}

.animated-logo {
    animation-duration: 1250ms;
    animation-timing-function: steps(75);
    animation-fill-mode: forwards;
    width: 194px;
    height: 167px;
    background-repeat: no-repeat;
}
.animated-logo.play {
    animation-name: play75;
}