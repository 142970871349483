.statisticsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(32);
}

.recommendationsContainer {
    display: grid;
    row-gap: rem(16);
    column-gap: rem(20);
    grid-template-columns: 1fr 1fr 1fr;
}
