.listFilter {
  padding: rem(15) rem(20) rem(20);

  &__dialogVariant {
    padding: rem(15) 0;
  }

  &__peopleFilter {
    padding: rem(15) 0 0 !important;
  }

  &__isEmpty {
    display: none;
  }

  &__field {
    height: rem(30);
    width: 100%;
    border: none;
    border-radius: rem(5);
    background-color: $colorInput--active;
    padding: 0 rem(10);
    font-size: rem(14);

    &.empty {
      text-align: center;
    }

    &:focus {
      text-align: left;
      box-shadow: none !important; // This text field does not need highlight box-shadow
    }
  }

  &--padding {
    &-vertical {
      padding: rem(15) 0;
    }

    &-horizontal {
      padding: 0 rem(15);

    }

    &-both {
      padding: rem(15) rem(15);
    }

    &-none {
      padding: 0;
    }
  }
}
