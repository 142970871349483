.iconLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $colorText;

    &__icon {
        margin-right: rem(8);

        & >:first-child {
            width: rem(18);
            height: rem(18);
        }
    }

    &__textContent {
        font-size: rem(18);
        font-weight: 600;
        line-height: rem(30);
    }
}