.statisticsCard {
    &.overallFillingRate {
        background: linear-gradient(249.26deg, #69F0AE 0%, #03A9F4 100%);
    }

    &.assignedMatrices {
        background: linear-gradient(248.49deg, #FF0844 -87.36%, #FFB199 100%);
    }

    &.matricesMeetingExpectations {
        background: linear-gradient(249.22deg, #A18CD1 0%, #73ADFF 100%);
    }

    min-height: rem(200);
    border-radius: rem(20);
    font-style: normal;
    color: white;

    &__art {
        &.overallFillingRate {
            background-image: url('assets/images/overview-overall-filling-rate.svg');
        }
    
        &.assignedMatrices {
            background-image: url('assets/images/overview-assigned-matrices.svg');
        }
    
        &.matricesMeetingExpectations {
            background-image: url('assets/images/overview-matrices-meeting-expectations.svg');
        }

        background-position: 80% 50%;
        background-repeat: no-repeat;
        padding: rem(20) rem(30);
    }

    &__title {
        font-size: rem(22);
        font-weight: bold;
        line-height: rem(28);
    }

    &__description {
        margin-top: rem(16);
        margin-bottom: rem(32);
        font-size: rem(80);
        font-weight: 600;
        line-height: rem(100);
    }
}
