.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorAction;
    color: $colorText;
    min-width: initial;

    &:hover {
        &:not(.badge--disabled) {
            background-color: $colorAction--hover;
        }

        .badge {
            &__removeButton {
                visibility: visible;
            }
        }
    }

    &:not(.badge--disabled):active {
        background-color: $colorAction--active;
    }

    &__removeButton {
        position: absolute;
        top: 0;
        right: rem(-8);
        visibility: hidden;
        width: rem(30) !important;
        border-radius: rem(10);
        color: $colorError !important;
        transition: color 0.35s ease;
        cursor: pointer;

        &:hover {
            color: $colorError--hover !important;
        }

        &:active {
            color: $colorError--active !important;
        }
    }

    &--sm {
        @include circle(30);

        .badge__title {
            font-size: rem(10);
        }
    }

    &--md {
        @include circle(55);

        .badge__title {
            font-size: rem(16);
        }
    }

    &--lg {
        @include circle(60);

        .badge__title {
            font-size: rem(18);
        }
    }

    &__title {
        font-weight: bold;
    }
}
