/**
 * HSL-based color palette of the application.
 */

// Primary color basis
$denim: hsl(214, 84, 50);
$denim--hover: darken($denim, 5%);
$denim--active: lighten($denim, 5%);
$denim--opaque: rgba($denim, 0.65);

// Secondary color basis
$springGreen: hsl(151, 100, 45);
$springGreen--hover: darken($springGreen, 5%);
$springGreen--active: lighten($springGreen, 5%);
$springGreen--activated: darken($springGreen, 5%);

// Text color basis
$nero: hsl(0, 0, 10);
$nero--secondary: lighten($nero, 12%);
$nero--information: lighten($nero, 30%);
$nero--disabled: lighten($nero, 55%);

// Action color basis
$whiteSmoke: hsl(0, 0, 97);
$whiteSmoke--disabled: darken($whiteSmoke, 10%);
$whiteSmoke--hover: darken($whiteSmoke, 4%);
$whiteSmoke--active: lighten($whiteSmoke, 2%);
$whiteSmoke--activated: darken($whiteSmoke, 1%);

// Selection color basis
$lavenderBlue: hsl(214, 84, 90);
$lavenderBlue--hover: darken($lavenderBlue, 5%);
$lavenderBlue--active: lighten($lavenderBlue, 2%);

// Input / border color basis
$solitude: hsl(204, 15, 94);
$solitude--active: lighten($solitude, 3%);
$solitude--opaque: rgba($solitude, 0.65);

// Error color basis
$burntSienna: hsl(0, 79, 63);
$burntSienna--hover: darken($burntSienna, 5%);
$burntSienna--active: lighten($burntSienna, 5%);

// Warning color basis
$gorse: hsl(49, 98, 60);
$gorse--highlighted: darken($gorse, 14%);

// Success color basis
$mediumAquamarine: hsl(160, 55, 55);

// Backdrop color basis
$cloudBurst: hsl(219, 23, 27);
$cloudBurst--hover: darken($cloudBurst, 5%);
$cloudBurst--active: lighten($cloudBurst, 5%);
$cloudBurst--opaque: rgba($cloudBurst, 0.65);
$cloudBurst--lightOpaque: rgba($cloudBurst, 0.35);

//
// Component based declarations
//
$colorPrimary: $denim;
$colorPrimary--hover: $denim--hover;
$colorPrimary--active: $denim--active;
$colorPrimary--opaque: $denim--opaque;

$colorSecondary: $springGreen;
$colorSecondary--hover: $springGreen--hover;
$colorSecondary--active: $springGreen--active;
$colorSecondary--activated: $springGreen--activated;

$colorText: $nero;
$colorText--secondary: $nero--secondary;
$colorText--information: $nero--information;
$colorText--disabled: $nero--disabled;
$colorText--highlighted: $colorPrimary;

$colorAction: $whiteSmoke;
$colorAction--hover: $whiteSmoke--hover;
$colorAction--active: $whiteSmoke--active;
$colorAction--activated: $whiteSmoke--activated;
$colorAction--disabled: $whiteSmoke--disabled;

$colorSelection: $denim;
$colorSelection--hover: $lavenderBlue--hover;
$colorSelection--active: $lavenderBlue--active;

$colorInput: $solitude;
$colorInput--active: $solitude--active;

$colorBorder: $solitude;

$colorBackground: $solitude;
$colorBackground--opaque: $solitude--opaque;

$colorError: $burntSienna;
$colorError--hover: $burntSienna--hover;
$colorError--active: $burntSienna--active;

$colorWarning: $gorse;
$colorWarning--highlighted: $gorse--highlighted;

$colorSuccess: $mediumAquamarine;

$colorBackdrop: $cloudBurst;
$colorBackdrop--hover: $cloudBurst--hover;
$colorBackdrop--active: $cloudBurst--active;
$colorBackdrop--opaque: $cloudBurst--opaque;
$colorBackdrop--lightOpaque: $cloudBurst--lightOpaque;
