.toggleSwitch {
    transition: transform 0.5s ease;

    &__background {
        transition: background-color .3s ease;
        position: absolute;
        width: rem(45);
        height: rem(20);
        border-radius: rem(10);
        background-color: $colorAction--disabled;
        cursor: pointer;

        &.selected {
            background-color: $colorPrimary;
        }

        &.small {
            width: rem(32);
            height: rem(14);
            border-radius: rem(7);
            margin-top: rem(4);
        }
    }

    &__slider {
        $sliderButtonSize: 26;

        transition: transform .3s ease;
        position: absolute;
        top: rem(-3);
        left: 0;
        right: initial;
        width: rem($sliderButtonSize);
        height: rem($sliderButtonSize);
        border-radius: rem($sliderButtonSize / 2);
        box-shadow: 0 0 rem(5) rgba(33, 33, 33, .45);
        background-color: white !important;
        z-index: 10;

        &.selected {
            transform: translateX(80%);
        }

        &.small {
            top: rem(-2);
            width: rem(18);
            height: rem(18);
            border-radius: rem(9);
        }
    }

    &__label {
        font-size: rem(16);
        padding-left: rem(55);
        color: $colorText--secondary;
        user-select: none;
        cursor: pointer;
        display: inline-block;
        margin-bottom: rem(8);

        &.small {
            padding-left: rem(40);
        }
    }
}
