$solidBorder: rem(1) solid $colorBorder;
$solidShadow: 0 rem(1) rem(2) 0 rgba(0, 0, 0, 0.1);

.personDetails {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 69px;
    padding: 0 15px 0 25px;
    z-index: 5;
    box-shadow: $solidShadow;
    flex: 0 0 69px;

    .placeholder {
      width: 150px !important;
      margin: 0 !important;
      align-self: center;
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 1000px;
    padding: 16px 0;
  }

  &__navBack {
    border: 1px solid #1A1A1A !important;
    min-width: 36px !important;
    width: 36px !important;
    height: 36px;
    border-radius: 4px !important;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__headerTitle {
    font-size: rem(18);
    font-weight: 600;
    max-width: 300px;
    box-shadow: none;
    overflow: hidden;
    padding: 0 !important;
    margin: 0 !important;
  }

  &__subtitle {
    font-size: rem(14);
    color: $colorText--information;
    margin-right: 5px;
  }
}


