.card {
    align-content: center;
    align-items: center;
    background-color: white;
    border-radius: rem(30);
    box-shadow: 0 rem(5) rem(30) rgba(33, 33, 33, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: rem(30) rem(35);

    &.small {
        height: rem(357);
        width: rem(605);
    }

    &.normal {
        min-height: rem(458);
        width: rem(605);
    }

    &__logo {
        position: relative;
        background-image: url('assets/images/coin-logo.svg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        width: rem(90);
        height: rem(90);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    &__title {
        color: $colorText;
        display: block;
        font-size: rem(25);
        font-weight: bold;
        margin-bottom: rem(10);
        text-align: center;
    }

    &__description {
        color: $colorText--secondary;
        display: block;
        font-size: rem(18);
        line-height: rem(23);
        text-align: center;
        width: 80%;

        &.welcomeCard {
            width: rem(354);
        }
    }

    &__link {
        @include linkify();
    }

    &__actions {
        align-self: flex-end;
        display: flex;
        font-size: rem(20);
        justify-content: center;
        text-align: center;
    }

    &__container {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;

        &--options-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        font-size: rem(20);
        align-items: center;
        width: 100%;
    }
}
