html,
body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
    color: $colorText;
    background-color: $colorBackground;

    * {
        box-sizing: border-box;

        &:focus {
            outline: none;
        }

        &::-moz-focus-inner {
            border: 0;
        }
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    textarea,
    select {
        outline: none;
        font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
        transition: background-color 0.25s ease;

        &:focus {
            background-color: $colorInput--active !important;
            box-shadow: 0 0 0 rem(2) $colorPrimary--opaque;
        }
    }

    a {
        text-decoration: none;
        color: inherit;

        &.colored {
            font-weight: 600;
            font-size: rem(18);

            @include linkify();
        }
    }

    #root {
        height: 100%;

        > div {
            height: 100%;
        }
    }

    .appContent {
        display: flex;
        width: 100%;
        height: auto;
        min-height: 100%;
        flex-direction: row;

        &__navigation {
            flex: 0 1 rem(110);
        }

        &__contentPanel {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            overflow-x: auto;
        }
    }

    .pageArea {
        display: flex;
        padding: rem(20) rem(30) rem(30);
        max-height: calc(100vh - 90px);
        overflow: auto;
        flex: 1 1 auto;
        flex-direction: column;
    }

    .no-select {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .colorful-background {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url('./assets/images/colorful-background.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bold {
        font-weight: bold;
    }

    .hidden {
        display: none !important;
    }

    .text-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .text--normal-wrap {
        white-space: normal;
    }

    .modern-scrollbar {
        @include modernScrollbar();
    }

    .tooltipIcon {
        cursor: help;
    }

    .grabIcon {
        background-image: url('assets/images/reorderButton.svg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        color: $colorText--information;
        margin-left: rem(3);
        width: rem(20);
        height: rem(18);
        cursor: grab;
    }

    .error {
        color: $colorError;
    }
}
