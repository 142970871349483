.contentBrowserListItem {
    display: flex;
    position: relative;
    width: 100%;
    height: rem(70);
    padding: rem(5) rem(20) rem(10);
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.35s ease;

    &.small {
        height: rem(60);
        cursor: default;

        .contentBrowserListItem__text {
            padding-left: rem(5);
        }
    }

    &:hover {
        .contentBrowserListItem {
            &__popover {
                visibility: visible;
            }

            &__additionalContent {
                &.showOnlyOnHover {
                    visibility: visible;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: rem(50);
        flex-grow: 1;
        padding-left: rem(10);
        margin-right: rem(20);
    }

    &__popover {
        visibility: hidden;
    }

    &__additionalContent {
        &.showOnlyOnHover {
            visibility: hidden;
        }
    }

    &__section {
        &:not(:first-child) {
            @include separatorLine();
        }   
    }
    

    &__titleWrapper {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: rem(18);

        &.small {
            font-size: rem(16);
            font-weight: 400;
        }
    }

    &__subtitle {
        font-size: rem(14);
        color: $colorText--information;
        margin-right: rem(5);
    }

    &__iconWrapper {
        color: $colorText--information;
        height: rem(40);
    }

    &__arrow {
        transition: transform 0.1s ease;
        cursor: pointer;

        &.rotate {
            transform: rotate(90deg);
        }
    }

    &__separator {
        display: block;
        position: absolute;
        top: 0;
        left: rem(25);
        width: 100%;
        height: rem(1);
        background-color: $colorBorder;
    }

    &__childWrapper {
        padding-left: rem(30);

        span.contentBrowserListItem {
            @include separatorLine();
        }
    }

    &__actionMenu {
        color: $colorPrimary;
        padding-right: rem(10);
        align-self: center;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__reactPlaceholder {
        width: rem(400) !important;
        padding: rem(20) rem(25);
    }

    &__trigger {
        align-items: center;
        min-width: initial;
        width: fit-content;
        height: rem(30);
        margin-left: rem(20);
        padding: 0;
        color: $colorPrimary;
        background-color: transparent;

        span {
            @include linkify();
        }
    }
}
