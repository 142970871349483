button,
input[type='submit'] {
    display: flex;
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    font-size: rem(15);
    height: rem(38);
    justify-content: center;
    min-width: rem(125);
    outline: none;
    transition: background-color .35s ease;
    border-radius: rem(19);
    border: none;
    cursor: pointer;

    &.smallButton {
        min-width: rem(100);
        height: rem(30);
        border-radius: rem(15);
        font-size: rem(16);
    }

    &.imageButton {
        background-color: transparent;
        min-width: initial;
        min-height: initial;
    }

    &.large {
        width: rem(250);
        height: rem(45);
        font-size: rem(18);
        border-radius: rem(23);
    }

    &.normal {
        width: rem(250);
        height: rem(40);
        font-size: rem(16);
        border-radius: rem(20);
    }

    &.defaultButton {
        background-color: $colorAction;
        color: $colorText--information;
        align-items: center;

        &:hover {
            background-color: $colorAction--hover;
        }

        &:active {
            background-color: $colorAction--active;
        }
    }

    &.primaryButton {
        background-color: $colorPrimary;
        color: white;
        font-weight: 600;
        align-items: center;

        &:hover {
            background-color: $colorPrimary--hover;
        }

        &:active {
            background-color: $colorPrimary--active;
        }

        &.destructive {
            background-color: $colorError;

            &:hover {
                background-color: $colorError--hover;
            }

            &:active {
                background-color: $colorError--active;
            }
        }

        &.disabled {
            pointer-events: none;
            background-color: $colorAction--disabled;
            color: $colorText--information;
        }
    }
}
