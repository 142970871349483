.ascend-appear {
    margin-right: rem(10) !important;
    opacity: 0.01 !important;
}

.ascend-appear.ascend-appear-active {
    margin-right: rem(35) !important;
    opacity: 1 !important;
    transition: margin-right 300ms ease-out, opacity 300ms ease-in !important;
}

.notification-transition-group {
    align-self: flex-start;
}

.notification {
    position: absolute;
    display: flex;
    flex-direction: row;
    z-index: 1301;
    right:0;
    top: 0;
    padding: rem(20) rem(20) rem(20) rem(15);

    min-height: rem(75);
    width: rem(450);

    align-items: center;
    justify-content: space-between;
    margin-top: rem(55);
    margin-right: rem(35);

    background-color: white;
    border-radius: rem(8);

    box-shadow: 0 rem(4) rem(15) rgba(33, 33, 33, 0.15);

    &__description {
        flex: 0 85%;
        margin-left: rem(5);
        font-size: rem(18);
        color: $colorText;
    }

    &__close {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-left: rem(5);
        width: rem(24);
        height: rem(24);
        border-radius: rem(12);
        cursor: pointer;
        color: $colorText--information;
        transition: background-color 0.35s ease;

        &:hover {
            background-color: $colorAction--hover;
        }

        &:active {
            background-color: $colorAction--active;
        }
    }
}
