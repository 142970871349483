.labeledInputField {
    align-self: stretch;
    min-height: rem(100);
    margin-bottom: rem(10);

    &__wrapper {
        display: flex;
        align-self: stretch;
        flex-direction: row-reverse;
        margin: rem(5) 0;
    }

    &__label {
        color: $colorText--information;
        font-size: rem(18);
    }

    &__input {
        flex: 1;
        height: rem(45);
        max-width: 100%;
        padding: 0 rem(15);
        font-size: rem(16);
        background-color: $colorInput;
        border-style: none;
        border-radius: rem(5);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: $colorText;

        &.with-action-button {
            padding-right: rem(45);
        }

        &.secure {
            letter-spacing: rem(5);
            font-weight: 700;
        }

        &.normal {
            color: $colorText;
        }

        &.disabled {
            color: $colorText--disabled;
        }
    }

    &__icon {
        width: rem(45);
        height: rem(45);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        color: $colorText--information;
        transition: color .35s ease;

        &:hover {
            color: $colorText--secondary;
        }
    }

    &__message {
        font-size: rem(13);
        color: $colorText--information;

        &--bold {
          font-weight: 700;
        }

        &--error {
          color: $colorError;
        }

        &.info {
          color: $colorWarning--highlighted;
        }
    }
}
