.worksheetNavigation {
    $navigationWidth: rem(275);
    $worksheetHeaderHeight: rem(45);
    $solidBorder: rem(1) solid $colorBorder;
    $solidShadow: 0 rem(1) rem(2) 0 rgba(0, 0, 0, 0.1);

    display: flex;
    position: relative;
    top: 0;
    left: 0;
    flex: 0 0 $navigationWidth;
    border-right: $solidBorder;
    flex-direction: column;

    &__header {
        display: flex;
        flex: 0 0 $worksheetHeaderHeight;
        z-index: 5;
        align-items: center;
        padding: 0 rem(15) 0 rem(10);
        border-radius: rem(15) 0 0;
        box-shadow: $solidShadow;
        overflow: hidden;
    }

    &__body {
        display: flex;
        flex: 1 0 auto;
        max-height: calc(100% - #{$worksheetHeaderHeight} - #{rem(65)});
    }

    &__emptyState {
        display: block;
        width: 100%;
        min-height: rem(25);
        line-height: rem(25);
        font-size: rem(14);
        color: $colorText--information;
        text-align: center;
        padding: 0 rem(15);

        &.error {
            color: $colorError;
        }
    }

    &__scrollArea {
        flex: 1 1 auto;
        border-bottom-left-radius: rem(15);
    }

    &__reactPlaceholder {
        padding: rem(20);
    }

    &__innerContent {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;

        &:after {
            position: relative;
            display: block;
            width: 100%;
            height: rem(30);
            content: '';
        }
    }

    &__listItem {
        display: block;
        transition: background-color 0.35s ease;

        &:not(.active) {
            &:hover {
                background-color: $colorAction--hover;
            }

            &:active {
                background-color: $colorAction--active;
            }
        }

        &.active {
            background-color: $colorAction--activated;
        }
    }
}
