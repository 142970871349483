.descriptionDialog {
    &__content {
        width: 100%;
        height: 100%;
        font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif !important;
        margin-bottom: rem(20);

        strong {
            font-weight: 600 !important;
        }

        a {
            @include linkify();
        }

        h2,
        h3 {
            margin: rem(8) 0 !important;
        }

        ol,
        ul,
        p {
            margin: 0 !important;
            overflow-wrap: break-word;
        }
    }

    &__label {
        display: inline-block;
        color: $colorText;
        font-size: rem(20);
        font-weight: bold;
        margin-bottom: rem(5);
    }

    &__description {
        margin-bottom: rem(20);
    }

    &__owners {
        display: flex;
        flex-direction: row;
    }

    &__footer {
        justify-content: space-between;
    }
}
