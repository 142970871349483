.labeledButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 rem(13) 0 rem(8);
    background-color: white;
    min-width: initial;
    height: rem(28);
    cursor: default;

    &:not(.inactive) {
        cursor: pointer;

        &:hover {
            background-color: $colorAction--hover;
        }

        &:active {
            background-color: $colorAction--active;
        }
    }

    &.inactive {
        color: $colorText--disabled;

        .labeledButton {
            &__icon {
                color: $colorText--disabled;
            }

            &__title {
                color: $colorText--disabled;
            }
        }
    }

    &__icon {
        $iconSize: rem(19);

        display: inline-flex;
        color: $colorPrimary;
        transition: color 0.35s ease;
        width: $iconSize;
        height: $iconSize;

        svg {
            width: $iconSize;
            height: $iconSize;
        }
    }

    &__title {
        color: $colorText--secondary;
        margin-left: rem(5);
        transition: color 0.35s ease;
    }
}
