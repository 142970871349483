.radioButtonGroup {
    display: flex;
    flex-direction: column;

    &--horizontal {
        align-self: stretch;
        align-content: center;
        justify-content: space-between;
        flex-flow: row nowrap;
        padding-bottom: rem(5);

        .radioButtonGroup__label {
            margin-bottom: initial;
        }
    }

    &:not(&--horizontal) {
        .radioButtonGroup__container {
            justify-content: flex-start;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        font-size: rem(18);
        color: $colorText--information;
        margin-bottom: rem(10);
    }

    &__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        min-width: rem(180);
    }
}
