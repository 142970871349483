.groupsContent {
    &__section {
        padding: rem(12) rem(20);
    }

    &__info {
        display: block;
        margin-top: rem(4);
        margin-bottom: rem(16);
        font-size: rem(18);
        font-weight: 600;
        color: $colorText--information;

        &--link {
            color: $colorPrimary;
        }
    }
}