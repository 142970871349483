/**
 * Creates a circle using a size value.
 */
@mixin circle($size) {
    width: rem($size);
    height: rem($size);
    border-radius: rem($size / 2);
}

/**
 * Creates a linkified element by using the application's standard
 * link styling rulesets.
 */
@mixin linkify() {
    color: $colorPrimary !important;
    transition: color 0.35s ease;
    cursor: pointer;

    &:hover {
        color: $colorPrimary--hover !important;
        text-decoration: underline;
    }

    &:active {
        color: $colorPrimary--active !important;
    }
}

/**
 * Creates a separator line 
 */
@mixin separatorLine() {
    &:before {
        content: "";
        top: 0;
        width: 100%;
        height: rem(1);
        background-color: $colorBorder;   
        display: block;
        position: absolute;
    }
}

@mixin modernScrollbar() {
    &::-webkit-scrollbar {
        width: rem(6);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: rem(3);
        background-color: $colorText--disabled;
        visibility: hidden;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $colorText--information;
    }

    &:hover::-webkit-scrollbar-thumb {
        visibility: visible;
    }
}
