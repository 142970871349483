.worksheetContentBrowser {
    $worksheetHeaderHeight: rem(45);
    $solidBorder: rem(1) solid $colorBorder;
    $solidShadow: 0 rem(1) rem(2) 0 rgba(0, 0, 0, 0.1);

    display: flex;
    position: relative;
    top: rem(40);
    min-width: rem(1000);
    height: 79vh;
    background-color: white;
    border-radius: rem(15);
    box-shadow: 0 rem(5) rem(15) rgba(0, 0, 0, 0.08);
    overflow: hidden;

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        min-width: 0;
    }

    &__section {
        span {
            &:not(:first-child) {
                @include separatorLine();
            }
        }
    }

    &__header {
        display: grid;
        grid-auto-flow: column;
        gap: 16px;
        justify-content: space-between;
        height: $worksheetHeaderHeight;
        line-height: $worksheetHeaderHeight;
        padding: 0 rem(15) 0 rem(25);
        z-index: 5;
        box-shadow: $solidShadow;

        .placeholder {
            width: rem(150) !important;
            margin: 0 !important;
            align-self: center;
        }
    }

    &__headerTitle {
        font-size: rem(18);
        font-weight: 600;
        max-width: 100%;
        box-shadow: none;
        overflow: hidden;
        padding: 0 !important;
        margin: 0 !important;
    }

    &__title {
        font-size: rem(18);
        font-weight: 600;
        max-width: rem(300);
        box-shadow: none;
        overflow: hidden;
    }

    &__emptyState {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .placeholder {
        width: rem(150) !important;
        margin: 0 !important;
        align-self: center;
    }

    &__actions {
        display: flex;
        align-items: center;
        box-shadow: none;
    }

    &__scrollArea {
        position: relative;
        overflow: hidden;
        height: 100%;
    }

    &__innerContent {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;

        &:after {
            position: relative;
            display: block;
            width: 100%;
            height: rem(50);
            content: '';
        }
    }

    &__title {
        font-size: rem(18);
        font-weight: 600;
        margin: rem(20) rem(25) rem(10);
        color: $colorSecondary--activated;
    }

    &__subtitle {
        font-size: rem(14);
        margin: rem(-5) rem(25) rem(10);
        display: flex;
    }

    &__emptyList {
        font-size: rem(16);
        max-width: rem(620);
        margin: rem(25);
        color: $colorText--information;
        line-height: rem(25);

        &.topMargin {
            margin-top: rem(15);
        }
    }
}
