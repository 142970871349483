.richTextEditor {
    margin-bottom: rem(10);

    &__wrapper {
        margin: rem(5) 0 rem(10);
        border-radius: rem(5);
        background-color: $colorInput;
        transition: background-color 0.25s ease;

        &:focus-within,
        &:active {
            background-color: $colorInput--active;
            box-shadow: 0 0 0 rem(2) $colorPrimary--opaque;
        }
    }
}

// Redeclaration of some React Quill styles
.ql {
    &-toolbar {
        border-radius: rem(5) rem(5) 0 0;
        border: none !important;
        font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif !important;

        button {
            &:hover {
                color: $colorPrimary !important;

                .ql-stroke {
                    stroke: $colorPrimary !important;
                }

                .ql-fill {
                    fill: $colorPrimary !important;
                }
            }
        }
    }

    &-container {
        border-radius: 0 0 rem(5) rem(5);
        border: none !important;
        font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif !important;
        overflow: hidden;
    }

    // Text editor area
    &-editor {
        height: rem(200);
        font-size: rem(16);

        @include modernScrollbar();

        &.ql-blank {
            &:before {
                color: $colorText--disabled !important;
                font-style: normal !important;
                font-size: rem(16);
            }
        }

        strong {
            font-weight: 600 !important;
        }

        a {
            @include linkify();
        }

        ol, ul {
            padding-left: initial !important;
            padding-inline-start: rem(35) !important;
        }

        li {
            padding-left: 0 !important;
            list-style-type: inherit !important;

            &:before {
                content: none !important;
            }
        }
    }

    // Font size picker
    &-picker {
        color: $colorText !important;

        &.ql-expanded {
            .ql-picker-label {
                color: $colorText !important;
                border-color: transparent !important;
                background-color: $colorAction--active;

                .ql-stroke {
                    stroke: $colorText !important;
                }
            }
        }
    }

    // Font size picker list items
    &-picker-options {
        z-index: 15 !important;
    }

    // Font size picker initial label
    &-picker-label {
        font-size: rem(16) !important;
        border-radius: rem(5);

        &.ql-active {
            color: $colorText--highlighted !important;

            polygon {
                stroke: $colorPrimary !important;
            }
        }

        &:hover {
            background-color: $colorAction--hover;
            color: $colorText !important;

            .ql-stroke {
                stroke: $colorText !important;
            }
        }
    }

    &-picker-item {
        &:hover {
            color: $colorText--highlighted !important;
        }

        &.ql-selected {
            color: $colorText--highlighted !important;
        }
    }

    &-expanded {
        &.ql-picker-label {
            color: $colorText !important;

            &.ql-active {
                color: $colorText--highlighted !important;
            }
        }
    }

    // Bold, italic, underline buttons
    &-formats {
        button {
            display: inline-flex !important;
            width: rem(40) !important;
            min-width: initial !important;
            align-items: center;
            border-radius: rem(5) !important;

            &:hover {
                background-color: $colorAction--hover;
            }

            &:active {
                background-color: $colorAction--active;
            }

            &:not(.ql-active) {
                &:hover,
                &:active {
                    .ql-stroke {
                        stroke: $colorText !important;
                    }

                    .ql-fill {
                        fill: $colorText !important;
                    }
                }
            }

            &.ql-active {
                &:hover,
                &:active {
                    .ql-stroke {
                        stroke: $colorText--highlighted !important;
                    }

                    .ql-fill {
                        fill: $colorText--highlighted !important;
                    }
                }
            }
        }
    }

    &-stroke {
        stroke: $colorText;
    }
}
