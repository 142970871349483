.creatableSelect {
    min-height: rem(100);
    margin-bottom: rem(10);

    &__label {
        color: $colorText--information;
        font-size: rem(18);
    }

    &__wrapper {
        margin: rem(5) 0;
    }

    &__input {
        &__control {
            height: initial;
            padding: rem(15);
            font-size: rem(16);
            background-color: $colorInput !important;
            border-style: none !important;
            border-radius: rem(5);
            -webkit-appearance: none !important;
            -moz-appearance: none !important;
            appearance: none !important;
            transition: background-color 0.25s ease !important;

            &--is-focused {
                border: none !important;
                box-shadow: 0 0 0 rem(2) $colorPrimary--opaque !important;
                background-color: $colorInput--active !important;
            }

            input {
                outline: none !important;
                box-shadow: none !important;
            }
        }

        &__menu {
            &-list {
                max-height: rem(155) !important;
            }
        }

        &__option {
            transition: background-color 0.35s ease !important;

            &--is-focused {
                background-color: $colorSelection--hover !important;
            }

            &--is-selected {
                background-color: $colorSelection !important;
            }

            &:active {
                background-color: $colorSelection--active !important;
            }
        }

        &__placeholder {
            color: black !important;
        }

        &__value-container {
            padding: 0 !important;
        }

        &__multi-value {
            &:nth-last-child(2) {
                margin-right: rem(5);
            }

            &__label {
                background-color: #d4dade;
                color: #1a1a1a !important;
                padding-left: initial !important; // Overriding value specified in library
                padding: rem(3) rem(8) !important;
                border-radius: rem(5) 0 0 rem(5) !important;
                font-weight: 600;
            }

            &__remove {
                background-color: #d4dade;
                cursor: pointer;
                border-radius: 0 rem(5) rem(5) 0 !important;
                color: #1a1a1a !important;

                &:hover {
                    background-color: #bec4c7 !important;
                }

                &:active {
                    background-color: #bec4c7 !important;
                }
            }
        }
    }

    &__message {
        font-size: rem(13);
        color: $colorText--information;
        align-self: flex-start;

        &--bold {
            font-weight: 700;
        }

        &.error {
            color: $colorError;
        }

        &.info {
            color: $colorWarning--highlighted;
        }
    }
}
