.ownerPopover {
    &__body {
        .MuiPaper-root {
            width: rem(265);
            height: rem(184);
            padding: rem(0) rem(0) rem(0) rem(0);
            border-radius: rem(15);
            box-shadow: 0 rem(4) rem(15) rgba(35, 31, 32, 0.15);
            margin-top: rem(-6);
            z-index: 1500;
            position: absolute !important;
            overflow: hidden;
        }

        &--error {
            .MuiPaper-root {
                width: rem(265) !important;
                height: rem(130) !important;
            }
        }
    }

    &__header {
        padding: 0 rem(10) !important;
    }

    &__title {
        display: block;
        color: $colorText;
        font-size: rem(16);
        font-weight: 600;
        margin-bottom: rem(5);
        padding: 0 rem(15);
    }

    &__menu {
        margin-top: rem(10);
        height: rem(126);
        overflow-y: auto;

        &--error {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100% !important;
            min-height: rem(25);
            font-size: rem(14);
            margin-top: 0;
            color: $colorText--information;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 0 rem(15);
        }
    }

    // List of available owners
    &__container {
        cursor: pointer;
        transition: background-color 0.35s ease;

        &:hover {
            background-color: $colorAction--hover;
        }

        &:active {
            background-color: $colorAction--active;
        }
    }

    &__emptyState {
        display: block;
        width: 100%;
        min-height: rem(25);
        line-height: rem(25);
        font-size: rem(14);
        color: $colorText--information;
        text-align: center;
        padding: 0 rem(15);

        &.error {
            color: $colorError;
        }
    }

    &__error {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: rem(25);
        font-size: rem(14);
        margin-top: rem(10);
        color: $colorText--information;
        text-align: center;
        align-items: center;
        padding: 0 rem(15);
    }

    &__errorText {
        padding-top: rem(8);
    }

    &__menuItem {
        color: $colorText;
        align-items: center;
        font-size: rem(16);
        font-weight: 600;
        height: rem(42);
        line-height: rem(45);
        margin: rem(0) rem(10) rem(0) rem(10);
        display: flex !important;
        flex-wrap: wrap !important;
    }

    &__separator {
        align-self: center;
        width: calc(100% - #{rem(20)});
        margin: rem(0) rem(10) rem(0) rem(10);
        height: rem(1);
        background-color: $colorBorder;
    }

    &__menuItemPeopleName {
        margin-left: rem(10) !important;
    }
}
