.dropdownSelector {
    &__button {
        display: flex;
        align-items: center;
        color: $colorText--highlighted;
        cursor: pointer;

        &.empty {
            cursor: default;
            color: $colorText--disabled;
        }
    }

    &__buttonTitle {
        max-width: rem(240);
    }

    &__menu {
        color: $colorText;
        position: absolute;
        top: rem(15);
        right: rem(15);
        width: rem(270);
        min-height: rem(80);
        text-align: left;
        line-height: rem(25);
        background-color: white;
        border-radius: rem(8);
        border: rem(1) solid $colorBorder;
        padding: rem(10) rem(10) rem(10);
        box-shadow: 0 rem(14) rem(25) rgba(0, 0, 0, .16);
        cursor: default;
        display: flex;
        flex-direction: column;
        z-index: 5;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 rem(5) rem(12) rem(5);
    }

    &__title {
        font-weight: 600;
    }

    &__listItem {
        height: rem(40);
        padding: rem(8) rem(15);
        transition: background-color .35s ease;
        border-bottom: rem(1) solid $colorBorder;
        color: $colorText;
        cursor: pointer;
        margin: 0 rem(-10);

        &.active {
            color: $colorText--highlighted;
            font-weight: 600;
        }

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: $colorAction--hover;
        }

        &:active {
            background-color: $colorAction--active;
        }
    }

    &__listItemText {
        display: block;
    }
}
