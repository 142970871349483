.exitButton {
    color: $colorText--information;
    background-color: $colorAction;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color .35s ease, background-color .3s ease;
    cursor: pointer;

    &:hover {
        background-color: $colorAction--hover;
    }

    &:active {
        background-color: $colorAction--active;
    }

    &--small {
        width: rem(28);
        height: rem(28);
        border-radius: rem(14);
    }

    &--medium {
        width: rem(32);
        height: rem(32);
        border-radius: rem(16);
    }

    &--large {
        width: rem(40);
        height: rem(40);
        border-radius: rem(20);
    }

    &--xlarge {
        width: rem(50);
        height: rem(50);
        border-radius: rem(25);
    }
}
