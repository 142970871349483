.badgeList {
    &__container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rem(5);
    }

    &__defaultBadge {
        width: rem(60);
        height: rem(60);
        margin: rem(5) rem(5) 0 0;
        border-radius: rem(30);
        background-color: $colorBackdrop--lightOpaque;
        box-sizing: border-box !important;
    }

    &__badge {
        width: rem(60);
        height: rem(60);
        border-radius: rem(30);
        margin: rem(5) rem(15) rem(5) 0;
        display: inline-block !important;
        position: relative;
        cursor: default;
    }
}
