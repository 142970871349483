.searchResultContent {
    &__section {
        padding: rem(12) rem(4);

    }
    &__innerContent {
        &:after {
            content: none;
        }
    }
    &__emptyState {
        padding: rem(32) 0;
    }
}
