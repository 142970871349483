.header {
    display: flex;
    background-color: white;
    box-sizing: border-box;
    border-bottom: rem(1) solid $colorBorder;
    z-index: 10;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 rem(90);
    padding: 0 rem(30);

    &__profileBadge {
        display: flex;
        border-radius: 50%;
        text-align: right;
        overflow: hidden;
        cursor: pointer;
        padding: 0 !important;
        max-width: rem(50) !important;
        min-width: rem(50) !important;

        img {
            height: rem(50);
            width: rem(50);
        }
    }

    &__dialog {
        display: flex;
    }
}
