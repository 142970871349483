.dialog {
    $sidePadding: rem(35);
    display: flex;
    width: rem(675);
    max-height: 85%;
    min-height: rem(200);
    background-color: white;
    box-shadow: 0 rem(5) rem(30) rgba(33, 33, 33, 0.3);
    border-radius: rem(30);
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    align-items: center;
    padding: rem(25) $sidePadding;

    &.fullScreen {
        width: rem(800);
        align-items: stretch;

        .dialog__body {
            width: initial;
            flex: 0 1 rem(650);
            overflow-y: auto;
            padding-bottom: rem(15);
            margin: 0 -#{$sidePadding};
            padding: 0 $sidePadding;
        }

        .dialog__footer {
            position: relative;
        }
    }

    &__search {
        flex: 0 0 rem(60);
    }

    &__listContainer {
        flex: 1 1 rem(275);
        overflow-y: auto;
        margin: 0 -#{$sidePadding};

        &--emptyState {
            max-width: rem(440);
            margin: 0 auto;
            text-align: center;
            color: $colorText--information;
            font-size: rem(14);
            line-height: rem(20);

            &.error {
                color: $colorError;
            }
        }

        .listItem {
            padding: rem(10) $sidePadding rem(15);

            &__separator {
                width: calc(100% - #{$sidePadding});
            }
        }
    }

    &__reactPlaceholder {
        padding: rem(20) rem(35);
    }

    &__informationWrapper {
        display: flex;
        align-items: center;
        flex: 0 0 rem(30);
        box-shadow: 0 rem(-5) rem(10) 0 white;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex: 0 0 rem(52);
    }

    &__body {
        display: flex;
        width: 100%;
        flex: 1 1 auto;
        flex-direction: column;
        min-height: 0;
        z-index: 2;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex: 0 0 rem(55);
        background-color: white;
        z-index: 3;
        box-shadow: 0 rem(-5) rem(10) 0 white;
    }

    &__titleContainer {
        display: flex;
        flex: 0 90%;
        flex-direction: column;
        padding-bottom: rem(5);
    }

    &__title {
        font-size: rem(25);
        font-weight: bold;
        color: $colorText;
        word-break: break-word;
    }

    &__subtitle {
        font-size: rem(16);
        color: $colorText--information;
        margin-top: rem(3);
    }

    &__form {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: rem(15);
        min-height: 0;

        &--confirm {
            margin-top: 0;
        }

        &.withoutMargin {
            margin-top: 0;
        }
    }

    &__info {
        display: block;
        font-size: rem(18);
        word-break: break-word;
        line-height: rem(25);
        margin: 0 -#{$sidePadding};
        padding: 0 $sidePadding;
        overflow-y: auto;
    }

    &__scrollArea {
        display: flex;
        flex: 0 1 auto;
        overflow-y: auto;
        flex-flow: column;
        margin: 0 -#{$sidePadding};
        padding: 0 $sidePadding;
        z-index: 2;

        &--smallBottomMargin {
            &:after {
                content: '';
                position: relative;
                width: 100%;
                height: rem(10);
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex: 0 0 rem(55);
        background-color: white;
        z-index: 3;
        box-shadow: 0 rem(-5) rem(10) 0 white;

        &--noShadow {
            box-shadow: none;
        }

        button {
            margin: 0;

            &:not(:last-child) {
                margin-right: rem(15);
            }
        }
    }

    &__toggleSwitch {
        display: inline-flex;
        align-items: center;

        > .toggleSwitch {
            margin-right: rem(3);
        }
    }

    &__buttonContainer {
        display: inline-flex;
        flex-direction: row;
    }

    &__actionContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
