.sectionHeader {
  display: flex;
  width: 100%;
  min-height: 3rem;
  padding: 0.625rem 1.25rem 0.9375rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  flex: 0 0 1.5625rem;

  & .listItem__title{
    color: #a6a6a6;
    font-weight: 700;
    font-size: 0.875rem;
  }
}