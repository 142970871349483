.activityIndicator {
    display: inline-block;
    position: relative;
    width: rem(100);
    height: rem(15);
    vertical-align: middle;
    margin-top: rem(-5);

    &.large {
        width: rem(40);
        height: rem(40);
    }

    &__bounce {
        display: inline-block;
        width: rem(12);
        height: rem(12);
        background-color: white;
        border-radius: 100%;
        animation: bounce-delay 1.25s infinite ease-in-out both;
        margin: 0 rem(4);

        &--shortDelay {
            animation-delay: -0.16s;
        }

        &--longDelay {
            animation-delay: -0.32s;
        }
    }
}
