.worksheet {
    $worksheetHeaderHeight: rem(45);
    $solidBorder: rem(1) solid $colorBorder;
    $solidShadow: 0 rem(1) rem(2) 0 rgba(0, 0, 0, 0.1);
    $sidebarWidth: rem(275);

    display: flex;
    position: relative;
    min-width: rem(1000);
    background-color: white;
    border-radius: rem(15);
    box-shadow: 0 rem(5) rem(15) rgba(0, 0, 0, 0.08);
    overflow: hidden;
    flex: 1 1 auto;

    &__link {
        font-size: rem(14);

        @include linkify();
    }

    &__sidebar {
        position: relative;
        top: 0;
        left: 0;
        flex: 1;
        max-width: $sidebarWidth;
        border-right: $solidBorder;
    }

    &__header {
        width: $sidebarWidth;
        display: flex;
        height: $worksheetHeaderHeight;
        line-height: $worksheetHeaderHeight;
        z-index: 5;
        box-shadow: $solidShadow;
        align-items: center;
        border-radius: rem(15) 0 0;
        border-right: rem(1) solid $colorBorder;
        padding: 0 rem(15) 0 rem(10);
    }

    &__emptyState {
        display: block;
        width: 100%;
        min-height: rem(25);
        line-height: rem(25);
        font-size: rem(14);
        color: $colorText--information;
        text-align: center;
        padding: 0 rem(15);
    }

    &__body {
        height: calc(100% - #{rem(110)});
    }

    &__scrollArea {
        height: 100%;
        overflow: hidden;
    }

    &__reactPlaceholder {
        padding: rem(20);
    }

    &__innerContent {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }
}

// Placeholder rows
.rect-shape {
    border-radius: rem(5);
    background-color: $colorBackground !important;
    height: rem(30) !important;
    width: auto !important;
    margin: rem(15) rem(20) rem(20) !important;
    padding: initial !important;
}

.text-row {
    height: rem(12) !important;
    border-radius: rem(6);
    background-color: $colorBackground !important;

    &:nth-child(even) {
        margin-bottom: rem(30);
        width: rem(100) !important;
        background-color: $colorBackground--opaque !important;
    }
}
