.menuBar {
    width: rem(110);
    height: 100%;
    background-color: white;
    border-right: rem(1) solid $colorBorder;
    box-sizing: border-box;
    padding: rem(8) rem(20);
    z-index: 15;
    flex: 0 0 rem(110);

    &__logo {
        position: relative;
        left: 0;
        right: 0;
        margin: rem(20) auto rem(30);
        width: rem(40);
        height: rem(40);
        background-image: url('assets/images/coin-logo.svg');
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
        }
    }
}

.menuItem {
    padding: rem(15) 0;
    border-radius: rem(15);
    transition: background-color 0.35s ease;
    cursor: default;
    color: $colorText--disabled;
    text-align: center !important;

    &:not(:last-child) {
        margin: 0 0 rem(40);
    }

    &:hover {
        background-color: $colorAction--hover;
    }

    &:active {
        background-color: $colorAction--active;
    }

    &__link {
        &.selected {
            color: $colorPrimary;
        }
    }

    &__icon {
        display: block;
    }

    &__title {
        margin-top: rem(3);
        font-size: rem(10);
        text-transform: uppercase;
    }
}
