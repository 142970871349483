.placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: rem(300);
    text-align: center;

    &__icon {
        color: $colorText;
        margin-bottom: rem(10);
    }

    &__title {
        color: $colorText;
        font-size: rem(25);
        font-weight: bold;
    }

    &__subtitle {
        color: $colorText--secondary;
        font-size: rem(16);
        margin-top: rem(5);
    }

    &__action {
        font-size: rem(16);
        margin-top: rem(20);

        @include linkify();
    }

    &--errorPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: rem(400);
        text-align: center;

        .placeholder {
            &__title {
                font-size: rem(65) !important;
                font-weight: 600 !important;
            }

            &__subtitle {
                line-height: rem(30) !important;
                font-size: rem(20) !important;
            }
        }
    }
}
