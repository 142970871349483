.sortableItem {
    &:not(:first-child) {
        span.contentBrowserListItem {
            @include separatorLine();
        }
    }

    &--hover {
        pointer-events: none !important;
    }

    &--drag {
        transition: background-color 0.35s ease;

        span {
            background-color: $colorAction--hover;
        }

        shadow {
            box-shadow: 0 rem(1) rem(2) 0 rgba(0, 0, 0, 0.1);
        }
    }
}