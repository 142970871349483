.input {
    align-self: stretch;
    height: rem(98);
    margin-bottom: rem(10);

    &__label {
        color: $colorText--information;
        font-size: rem(18);
        align-self: flex-start;
    }

    &__wrapper {
        display: flex;
        align-self: stretch;
        flex-direction: row-reverse;
        margin: rem(5) 0;
    }

    &__field {
        flex: 1;
        height: rem(45);
        max-width: 100%;
        padding-left: rem(15);
        padding-right: rem(15);
        font-size: rem(18);
        background-color: $colorInput;
        border-style: none;
        border-radius: rem(5);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.with-action-button {
            padding-right: rem(45);
        }

        &.secure {
            letter-spacing: rem(5);
            font-weight: 1000;
        }

        .normal {
            color: black;
        }

        &.disabled {
            color: $colorText--disabled;
        }
    }

    &__textArea {
        flex: 1;
        height: rem(106);
        padding: rem(15);
        font-size: rem(18);
        background-color: $colorInput;
        border-style: none;
        border-radius: rem(5);
        resize: none;

        &::placeholder {
            color: $colorText--disabled;
            opacity: 1; // Firefox
        }
    }

    &__imageView {
        width: rem(45);
        height: rem(45);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        color: $colorText--information;
        transition: color .35s ease;

        &:hover {
            color: $colorText--secondary;
        }

        // TODO: Active?
    }

    &__information {
        font-size: rem(13);
        color: $colorText--information;
        align-self: flex-start;

        &.error {
            color: $colorError;
        }

        &.info {
            color: $colorWarning--highlighted;
        }
        &--withMarginBottomMd {
            margin-bottom: 0.5rem;
        }
    }

    &.textArea {
        flex: 1;
    }
}
