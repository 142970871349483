.profilePopover {
    &__trigger {
        display: flex;
        position: absolute;
        height: rem(50);
        width: rem(50);
        border-radius: 50%;
        text-align: right;
        overflow: hidden;
        cursor: pointer;
        padding: 0 !important;
        min-width: initial;
        background: none;

        img {
            height: rem(50);
            width: rem(50);
        }
    }

    &__body {
        .MuiPaper-root {
            width: rem(235);
            padding: rem(15) 0 rem(10);
            border-radius: rem(8);
            margin-top: rem(5);
        }
    }

    &__title {
        display: block;
        color: $colorText;
        font-size: rem(16);
        font-weight: bold;
        margin-bottom: rem(5);
        padding: 0 rem(15);
    }

    &__menu {
        margin-top: rem(10);
    }

    &__menuItem {
        font-size: rem(16);
        color: $colorText--secondary !important;
        height: rem(45);
        line-height: rem(45);
        padding: 0 rem(15) !important;
        transition: background-color 0.35s ease;

        &:hover {
            background-color: $colorAction--hover !important;
        }

        &:active {
            background-color: $colorAction--active !important;
        }

        &:first-child {
            border-bottom: rem(1) solid $colorBorder;
        }
    }
}
