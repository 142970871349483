.listItem {
    display: flex;
    width: 100%;
    min-height: rem(80);
    padding: rem(10) rem(20) rem(15);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.35s ease;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    &__title {
        display: flex;
        align-items: center;
        max-width: rem(190);
        font-weight: 600;
        font-size: rem(18);
        color: $colorText;
    }

    &__primaryTitle {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__title--maxWidth {
        max-width: 100%;
        overflow-wrap: break-word;
    }

    &__subtitle {
        margin-top: rem(5);
        max-width: rem(190);
        font-size: rem(14);
        color: $colorText--information;
    }

    &__labels {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: rem(5) 0 0 0;
        max-width: 80%;

        &__label {
            background-color: #d4dade;
            color: #1a1a1a !important;
            padding: rem(5);
            margin: rem(5) rem(5) 0 0;
            border-radius: rem(5) !important;
            font-size: rem(12);
            font-weight: 600;
        }
    }

    &__separator {
        align-self: flex-end;
        width: calc(100% - #{rem(20)});
        height: rem(1);
        background-color: $colorBackground;
    }

    &__action {
        height: rem(26);
        width: rem(26);
        border-radius: rem(13);
        color: $colorText--secondary;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.35s ease;

        &:hover {
            background-color: $colorAction--hover;
        }

        &:active {
            background-color: $colorAction--active;
        }
    }

    &__badge {
        margin-left: rem(10);
    }
}
